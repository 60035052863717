<template>
	<el-row class="warp">
		<el-col :span="24" class="warp-breadcrum">
				<el-breadcrumb separator="/">
				<el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
				<el-breadcrumb-item>内容管理</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/accommodation/list' }">住宿</el-breadcrumb-item>
				<el-breadcrumb-item>编辑</el-breadcrumb-item>
			</el-breadcrumb>
		</el-col>

		<el-col :span="24" class="warp-main">
			<el-form ref="form" :model="form" :rules="rules" label-width="150px" class="el-form-self">
				<el-row>
					<el-col>
						<el-form-item label="住宿类型:" prop="type" class="is-required">
							<el-select v-model="form.type" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>

			<el-row>
				<el-col>
					<el-form-item label="名称:" prop="name" class="is-required">
						<el-input v-model="form.name" auto-complete="off" maxlength="50" show-word-limit placeholder="请输入名称"></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="简介:" prop="introduction">
						<el-input type="textarea" v-model="form.introduction" auto-complete="off" maxlength="1000" show-word-limit placeholder="请输入简介">
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="外链:" prop="outerLinkUrl">
						<el-input v-model="form.outerLinkUrl" auto-complete="off" placeholder="请输入外链" maxlength="200" show-word-limit clearable></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="住址:" prop="address">
						<el-input
							v-model="form.address"
							auto-complete="off"
							placeholder="请输入住址" maxlength="200" show-word-limit
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="联系电话:" prop="contactNumber">
						<el-input
							v-model="form.contactNumber"
							auto-complete="off"
							placeholder="请输入联系电话" maxlength="12" show-word-limit
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="列表缩略图片:" prop="imageUrl" class="is-required">
						<el-upload class="homeBannerImage-uploader" :action="uploadFileURL" :show-file-list="false"
							:on-success="handleBannerImageSuccess1" :before-upload="beforeAccommodationmageUpload">
							<img v-if="indexImageUrl" :src="indexImageUrl" class="homeBannerImage"/>
							<i v-else class="el-icon-plus homeBannerImage-uploader-icon" ></i>
							<div slot="tip" class="el-upload__tip">
								注意：点击 + 上传.jpg,
								.png后缀图片文件。图片尺寸为220px x 220px。
							</div>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="详情页图片:" prop="imageUrl" class="is-required">
						<el-upload class="homeBannerImage-uploader" :action="uploadFileURL"
							:show-file-list="false" :on-success="handleBannerImageSuccess" :before-upload="beforeAccommodationmageUpload">
							<img v-if="imageUrl" :src="imageUrl" class="homeBannerImage"/>
							<i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
							<div slot="tip" class="el-upload__tip">
								注意：点击 + 上传.jpg,
								.png后缀图片文件。图片尺寸为720px x 480px。
							</div>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :span="24">
					<el-form-item label="内容:">
						<Editor
							id="tinymce"
							v-model="form.content"
							:init="editorInit"
						></Editor>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="置顶:" prop="isTop" class="is-required">
						<el-radio v-model="form.isTop" label="是">是</el-radio>
						<el-radio v-model="form.isTop" label="否">否</el-radio>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col>
					<el-form-item label="推荐:" prop="recommended" class="is-required">
						<el-radio v-model="form.recommended" label="是">是</el-radio>
						<el-radio v-model="form.recommended" label="否">否</el-radio>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row class="form-buttons">
				<el-form-item class="el-form-item-self">
					<el-button type="primary" @click="$router.push({path: '/accommodation/list',query: {filtersName: $route.query.filtersName,},})">返回</el-button>
					<el-button type="primary" @click="onSubmit">保存</el-button>
					<el-button type="primary" @click="temporarySubmit">发布</el-button>
				</el-form-item>
			</el-row>
			</el-form>
		</el-col>
	</el-row>
</template>
<script>
import {reqEditAccommodation,reqGetAccommodationOne,} from '../../../api/accommodation-api'
import { uploadFileURL, downloadFileURL } from '../../../api/system-api'
import { SERVER_HOST } from '../../../common/portConfig'
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/plugins/code'
import 'tinymce/plugins/print'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/save'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/image'
import 'tinymce/plugins/media'
import 'tinymce/plugins/link'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/table'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/hr'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/toc'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/imagetools'
import 'tinymce/plugins/textpattern'
import 'tinymce/plugins/noneditable'
import 'tinymce/plugins/help'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/indent2em'
import 'tinymce/icons/default/icons'
import '../../../../public/static/tinymce/langs/zh_CN.js'
export default {
	components: {
		Editor,
	},
	data() {
		return {
			options: [
				{
					value: '0',
					label: '酒店',
				},
				{
					value: '1',
					label: '名宿',
				},
				{
					value: '2',
					label: '青旅',
				},
			],
			videoUrlList: [],
			value: '',
			addPath: '/accommodation/list',
			filtersName: '',
			editPath: '/accommodation/list',
			imageUrl: '',
			indexImageUrl: '',
			columnsTypeArray: [],
			uploadFileURL: uploadFileURL,
			form: {
				contactNumber: '',
				address: '',
				indexImageUrl: '',
				id: 0,
				name: '',
				introduction: '',
				isTop: '',
				recommended: '',
				outerLinkUrl: '',
				videoName: '',
				videoUrl: '',
				status: '',
				publisher: '',
				columns: '',
				content: '',
				numberOrder: '',
				isUse: '',
				type: '',
				imageUrl: '',
				outerLink: '',
				showOuterLinkStatus: false,
			},
			rules: {
				type:[
					{required: true,message: '请选择住宿类型',trigger: 'change'},
				],
				name:[
					{required: true,message: '请输入名称',trigger: 'blur'},
				],
				imageUrl:[
					{required: true, message: '请上传图片'},
				],
				indexImageUrl:[
					{required: true, message: '请上传列表缩略图片'},
				],
				contactNumber:[
					 {required: true, message: '请输入联系电话', trigger: 'blur'},
					{pattern: /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$|^(400-)(\d{7})$|^1[3|4|5|7|8|9][0-9]{9}$/, message:'请输入正确的联系电话',trigger: 'blur'},
				],
				content:[
                        {required: true, message: '请输入内容', trigger: 'change'},
                ],
			},
			editorInit: {
				language_url: '/static/tinymce/langs/zh_CN.js',
				language: 'zh_CN',
				skin_url: '/static/tinymce/skins/ui/oxide',
				content_css:
					'/static/tinymce/skins/content/default/content.css',
				height: 800,
				font_formats:
					'微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;',
				plugins:
					'code print indent2em preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars media',
				toolbar:
					'undo redo |code bold italic underline strikethrough indent2em | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
				file_picker_types: 'media file',
				// images_upload_url: '/editorfile/uploadFile',
				autosave_ask_before_unload: false,
				file_picker_callback: function(callback, type, meta) {
					if (meta.filetype === 'media') {
						var input = document.createElement('input')
						input.setAttribute('type', 'file')
						//你可以给input加accept属性来限制上传的文件类型
						//例如：input.setAttribute('accept', '.jpg,.png');
						input.setAttribute('accept', '.mp4')
						input.click()
						input.oninput = function() {
							var file = this.files[0]

							var xhr, formData
							console.log(file.name)
							xhr = new XMLHttpRequest()
							xhr.open(
								'POST',
								SERVER_HOST + '/editorfile/uploadVideoFile'
							)
							// xhr.withCredentials = true ;
							xhr.onload = function() {
								var json
								if (xhr.status != 200) {
									alert('上传视频失败')
									return false
								}
								json = JSON.parse(xhr.responseText)
								if (!json || typeof json.location != 'string') {
									alert('上传视频失败')
									return false
								}
								callback(json.location)
							}
							formData = new FormData()
							if (file.size / 1024 / 1024 > 100) {
								alert('上传视频不能超过100m')
								return false
							}
							formData.append('file', file, file.name)
							xhr.send(formData)
						}
					} else if (meta.filetype === 'file') {
						var input = document.createElement('input')
						input.setAttribute('type', 'file')
						//你可以给input加accept属性来限制上传的文件类型
						//例如：input.setAttribute('accept', '.jpg,.png');
						input.setAttribute(
							'accept',
							'.xls,.xlsx,.doc,.docx,.pdf'
						)
						input.click()
						input.oninput = function() {
							var file = this.files[0]

							var xhr, formData
							console.log(file.name)
							xhr = new XMLHttpRequest()
							xhr.open(
								'POST',
								SERVER_HOST + '/editorfile/uploadFile'
							)
							xhr.withCredentials = true
							xhr.onload = function() {
								var json
								if (xhr.status != 200) {
									alert('上传失败')
									return false
								}
								json = JSON.parse(xhr.responseText)
								if (!json || typeof json.data != 'string') {
									alert('上传失败')
									return false
								}
								callback(json.data, { text: '' })
							}
							formData = new FormData()
							if (file.size / 1024 / 1024 > 20) {
								alert('上传失败')
								return false
							}
							formData.append('file', file, file.name)
							xhr.send(formData)
						}
					}
				},
				images_upload_handler: function(blobInfo, succFun, failFun) {
					var xhr, formData
					var file = blobInfo.blob() //转化为易于理解的file对象
					xhr = new XMLHttpRequest()
					xhr.open(
						'POST',
						SERVER_HOST + '/editorfile/uploadFile',
						true
					)
					xhr.withCredentials = true
					xhr.onload = function() {
						var json
						if (xhr.status != 200) {
							failFun('上传图片失败')
							return
						}
						json = JSON.parse(xhr.responseText)
						if (!json || typeof json.data != 'string') {
							failFun('上传图片失败')
							return
						}
						succFun(json.data)
					}
					formData = new FormData()
					formData.append('file', file, file.name)
					xhr.send(formData)
				},
			},
		}
	},
	methods: {
		onSubmit() {
			var that = this
			that.form.status = '1'
            delete that.form['createTime'];
            delete that.form['updateTime'];
			if (that.form.content.length > 20000) {
				that.$message({
					type: 'warning',
					message: '内容不能超过20000个字符',
				});
				return;
			}
			var para = Object.assign({}, that.form);
			this.$refs.form.validate((valid) => {
				if (valid) {
					let para = Object.assign({}, this.form)
					reqEditAccommodation(para).then((res) => {
						if (res.data.code === 1) {
							that.$message({
								message: '保存成功',
								type: 'success',
							})
							that.$refs.form.resetFields()
							that.$router.push({ path: that.editPath })
						} else {
							that.$message({
								message: '保存失败',
								type: 'error',
							})
							that.$refs.form.resetFields()
							that.$router.push({ path: that.editPath })
						}
					})
				}
			})
		},
		temporarySubmit() {
			var that = this
			this.$refs.form.validate((valid) => {
				if (valid) {
					that.form.status = '0'
					that.form.systemUser = sessionStorage.getItem(
						'Authorization'
					)
				if (that.form.content.length > 20000) {
					that.$message({
						type: 'warning',
						message: '内容不能超过20000个字符',
					});
					return;
				}
					delete that.form['createTime']
					delete that.form['updateTime']
					var para = Object.assign({}, that.form)
					reqEditAccommodation(para).then((res) => {
						if (res.data.code === 1) {
							that.$message({
								message: '发布成功',
								type: 'success',
							})
							that.$refs.form.resetFields()
							that.$router.push({ path: that.addPath })
						} else {
							that.$message({
								message: '发布失败',
								type: 'error',
							})
						}
					})
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},

		beforeAccommodationmageUpload(file) {
			const isValidType =
				file.type === 'image/jpeg' ||
				file.type === 'image/png' ||
				file.type === 'image/gif' ||
				file.type === 'image/bmp' ||
				file.type === 'image/tiff' ||
				file.type === 'application/postscript' ||
				file.type === 'audio/mp3' || file.type === "audio/mpeg"||
				file.type === 'video/mp4' ||
				file.type === 'audio/x-m4a' ||
				file.type === 'audio/aac' ||
				file.type === 'audio/wav' ||
				file.type === 'video/3gpp' ||
				file.type === 'video/x-ms-wmv' ||
				file.type === 'text/plain' ||
				file.type === 'application/msword' ||
				file.type ===
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
				file.type === 'application/vnd.ms-excel' ||
				file.type ===
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
				file.type === 'application/vnd.ms-powerpoint' ||
				file.type ===
					'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
				file.type === 'application/pdf' ||
				file.type === 'application/x-7z-compressed' ||
				file.type === 'application/zip' ||
				file.type === 'application/x-rar'
			const isLt20M = file.size / 1024 / 1024 < 20
			if (!isValidType) {
				this.$message.error('不支持该类型的文件上传。')
				return false
			}
			if (!isLt20M) {
				this.$message.error('上传文件大小不能超过 20MB!')
				return false
			}
			return true
		},

		handleBannerImageSuccess(response) {
			if (response.code === 1) {
				this.form.imageUrl = response.data
				this.imageUrl = downloadFileURL + response.data + '/'
			}
		},
		handleBannerImageSuccess1(response) {
			if (response.code === 1) {
				this.form.indexImageUrl = response.data
				this.indexImageUrl = downloadFileURL + response.data + '/'
			}
		},
		getAccommodation(id) {
			var that = this
			reqGetAccommodationOne({ id: id }).then((res) => {
				if (res.data.data.imageUrl) {
					that.imageUrl =
						downloadFileURL + res.data.data.imageUrl + '/'
				} else {
					that.imageUrl = ''
				}
				if (res.data.data.indexImageUrl) {
					this.indexImageUrl =
						downloadFileURL + res.data.data.indexImageUrl + '/'
				} else {
					this.indexImageUrl = ''
				}

				this.value = res.data.data.type
				that.form = Object.assign({}, res.data.data)

				if (that.form.outerLink) {
					that.form.showOuterLinkStatus = true
				}
				this.videoUrlList.push({
					url: this.form.videoUrl,
					name: this.form.videoName,
				})
			})
		},
	},
	mounted() {
		let id = this.$route.query.id
		this.getAccommodation(id)
		  tinymce.init({});
	},
}
</script>
